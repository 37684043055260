import * as React from "react";
import { graphql } from "gatsby";
import { IGatsbyImageData } from "gatsby-plugin-image";
import { Article, MediaCentreTemplate } from "../templates/media-centre/media-centre.template";

type TemplateProps = {
  data: {
    page: {
      articles: {
        articleData: {
          id: string;
          uid: string;
          url: string;
          data: {
            type: "H2FA_NEWS" | "IN_HYDROGEN";
            title: {
              text: string;
            };
            description: {
              text: string;
            };
            date: string;
            thumbnail_image: {
              gatsbyImageData: IGatsbyImageData;
            };
            tags: string;
            is_external_article: boolean;
            external_article: {
              url: string;
            };
          };
        };
      }[];
    };
    external: {
      articles: {
        articleData: {
          id: string;
          uid: string;
          data: {
            type: "H2FA_NEWS" | "IN_HYDROGEN";
            title: {
              text: string;
            };
            description: {
              text: string;
            };
            date: string;
            thumbnail_image: {
              gatsbyImageData: IGatsbyImageData;
            };
            tags: string;
            article_url: {
              url: string;
            };
          };
        };
      }[];
    };
  };
};

export const MediaCentrePage = (props: never) => {
  const { data }: TemplateProps = props;
  const internalArticles: Article[] = data.page.articles.map((it) => ({
    id: it.articleData.id,
    articleType: "internal",
    slug: it.articleData.url,
    type: it.articleData.data.type,
    title: it.articleData.data.title.text,
    date: new Date(it.articleData.data?.date ?? null),
    description: it.articleData.data.description.text,
    thumbnail: it.articleData.data?.thumbnail_image?.gatsbyImageData,
    tags: it.articleData.data.tags?.split(","),
  }));
  const externalArticles: Article[] = data.external.articles.map((it) => ({
    id: it.articleData.id,
    articleType: "external",
    type: it.articleData.data.type,
    title: it.articleData.data.title.text,
    date: new Date(it.articleData.data?.date ?? null),
    description: it.articleData.data.description.text,
    url: it.articleData.data.article_url?.url,
    thumbnail: it.articleData.data?.thumbnail_image?.gatsbyImageData,
    tags: it.articleData.data.tags?.split(","),
  }));
  return <MediaCentreTemplate articles={internalArticles.concat(externalArticles)} />;
};

export const query = graphql`
  query {
    page: allPrismicMediaCentreArticlePage {
      articles: edges {
        articleData: node {
          id
          uid
          url
          data {
            title {
              text
            }
            description {
              text
            }
            thumbnail_image {
              gatsbyImageData(placeholder: BLURRED, width: 380, height: 260)
            }
            date
            type
            tags
          }
        }
      }
    }
    external: allPrismicMediaCentreArticleExternal {
      articles: edges {
        articleData: node {
          id
          uid
          url
          data {
            article_url {
              id
              url
            }
            title {
              text
            }
            description {
              text
            }
            thumbnail_image {
              gatsbyImageData(placeholder: BLURRED, width: 380, height: 260)
            }
            date
            type
            tags
          }
        }
      }
    }
  }
`;

export default MediaCentrePage;
