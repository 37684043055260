import format from "date-fns/format";
import * as React from "react";
import { Link as GatsbyLink } from "gatsby";
import { GatsbyImage, IGatsbyImageData, StaticImage } from "gatsby-plugin-image";
import { Page } from "../../components/templates/page/page";
import { SectionHero, SectionHeroImageCss } from "../../components/templates/page/section/section-hero";
import { atoms, Box, Heading, Link, Paragraph, Stack, Text } from "../../design-system";
import { DownloadIcon } from "../../design-system/components/icon/icon";
import { Section } from "../_components/section";
import { articlesGrid } from "./media-centre-template.css";

type Props = {
  articles: Article[];
};

export type Article = {
  id: string;
  type: "H2FA_NEWS" | "IN_HYDROGEN";
  title: string;
  description: string;
  date: Date;
  thumbnail: IGatsbyImageData;
  tags: string[];
} & (
  | {
      articleType: "internal";
      slug: string;
    }
  | {
      articleType: "external";
      url: string;
    }
);

export const MediaCentreTemplate = ({ articles }: Props) => {
  return (
    <Page title={"Media Centre"}>
      <SectionHero
        title={"Media Centre"}
        image={
          <StaticImage
            src={`../assets/images/hero/hero-hydrogen-for-mobility.png`}
            style={SectionHeroImageCss}
            placeholder="dominantColor"
            alt=""
          />
        }
      />
      <Section>
        <Heading
          as={"h2"}
          size={"lg"}
          fontFamily={"secondary"}
          fontWeight={"book"}
          textTransform={"uppercase"}
          mb={"xl"}
        >
          H2FA NEWS
        </Heading>
        <ArticlesGrid
          articles={articles
            .filter((it) => it.type === "H2FA_NEWS")
            .sort((a, b) => b.date.getTime() - a.date.getTime())}
        />
      </Section>
      <Section>
        <Heading
          as={"h2"}
          size={"lg"}
          fontFamily={"secondary"}
          fontWeight={"book"}
          textTransform={"uppercase"}
          mb={"xl"}
          data-testid={"haha"}
        >
          In Hydrogen
        </Heading>
        <ArticlesGrid
          articles={articles
            .filter((it) => it.type === "IN_HYDROGEN")
            .sort((a, b) => b.date.getTime() - a.date.getTime())}
        />
      </Section>
      <Section backgroundColor={"icywaters"}>
        <Stack space={"md"} direction={["column", "row"]} justifyContent={"space-between"}>
          <Heading
            as={"h4"}
            size={"lg"}
            color={"white"}
            fontFamily={"secondary"}
            fontWeight={"book"}
            textTransform={"uppercase"}
            style={{ maxWidth: 100 }}
          >
            Media Resources
          </Heading>
          <Stack space={"sm"}>
            <Text color={"white"} style={{ maxWidth: 500 }}>
              If you wish to publish, reproduce or distribute images and/or for all media enquiries, please contact us
              at{" "}
              <Link colorScheme={"white"} underline href="mailto:media@h2fa.com.au">
                media@h2fa.com.au
              </Link>
              .
            </Text>
            <Link colorScheme={"white"} href={""} underline>
              About Hydrogen Fuels Australia Pty Ltd
              <DownloadIcon size={20} shiftBy={[16, 4]} />
            </Link>
          </Stack>
        </Stack>
      </Section>
    </Page>
  );
};

const ArticlesGrid = ({ articles }: { articles: Article[] }) => (
  <div className={articlesGrid}>
    {articles.map((props) => (
      <ArticlePanel key={props.id} {...props} />
    ))}
  </div>
);

const ArticlePanel = (article: Article) => {
  const limitedText = article.description.substring(0, 200);
  const formattedDescription = `${limitedText.substring(0, limitedText.lastIndexOf(" "))}...`;
  const classes = atoms({
    color: "inherit",
    textDecoration: "none",
    outline: "none",
  });
  const Link = (props: { children: React.ReactNode }) =>
    article.articleType === "external" ? (
      <a className={classes} href={article.url} target={"_blank"}>
        {props.children}
      </a>
    ) : (
      <GatsbyLink className={classes} to={article.slug}>
        {props.children}
      </GatsbyLink>
    );
  return (
    <Link>
      <article>
        {article.thumbnail && (
          <GatsbyImage
            className={atoms({
              width: "full",
              marginBottom: "lg",
            })}
            image={article.thumbnail}
            objectFit={"fill"}
            alt={""}
          />
        )}
        <Stack space={"md"}>
          {article.tags && (
            <Stack space={"sm"} alignX={"left"}>
              {article.tags.map((it) => (
                <Tag key={it}>{it}</Tag>
              ))}
            </Stack>
          )}
          <Heading as={"h3"} size={"md"} fontWeight={"bold"}>
            {article.title}
          </Heading>
          <Paragraph fontWeight={"bold"}>{format(article.date, "dd MMMM, yyyy")}</Paragraph>
          <Paragraph>{formattedDescription}</Paragraph>
        </Stack>
      </article>
    </Link>
  );
};

const Tag = (props: { children: React.ReactNode }) => (
  <Box
    py={"xs"}
    px={"sm"}
    fontSize={"2xs"}
    background={"primary400"}
    color={"white"}
    borderRadius={"lg"}
    textTransform={"uppercase"}
  >
    {props.children}
  </Box>
);
